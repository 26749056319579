<template>
  <v-dialog
    :value="previewIsFullscreen"
    :width="isMobile ? '600' : '1000'"
    @input="$emit('close-dialog')"
  >
    <v-card height="50vh" dark>
      <instream-preview :creative="creative" preview-is-update />
    </v-card>
  </v-dialog>
</template>

<script>
  import InstreamPreview from '@/components/ads/InstreamForm/InstreamPreview.vue'

  export default {
    name: 'InstreamPreviewDialog',
    components: { InstreamPreview },
    props: {
      creative: {
        type: Object,
        required: true
      },
      previewIsFullscreen: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    overflow-y: hidden;
  }
</style>
