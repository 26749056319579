<template>
  <v-row>
    <v-col class="py-0" cols="12" sm="6">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_AD_FORM_IMPRESSIONS_THRESHOLD"
        :value="impressionsThreshold"
        type="number"
        label-bold
        background-color="white"
        :label="$t('main.ad_form.impressions')"
        class="flex-fill"
        :messages="$t('main.ad_form.impressions_threshold_label')"
        :errors="errors.impressions_threshold"
        @input="setImpressionsThreshold($event)"
      >
        <template #message="{ message }">
          <div class="info-lighten--text text-caption ml-n3">
            {{ message }}
          </div>
        </template>
      </c-text-field>
    </v-col>
    <v-col class="py-0 mt-3 mt-sm-0" cols="12" sm="6">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_AD_FORM_HOURS_THRESHOLD"
        :value="hoursThreshold"
        type="number"
        label-bold
        background-color="white"
        :label="$t('main.ad_form.time')"
        class="flex-grow-1"
        :errors="errors.hours_threshold"
        :messages="$t('main.ad_form.hours_threshold_label')"
        @input="setHoursThreshold($event)"
      >
        <template #message="{ message }">
          <div class="info-lighten--text text-caption ml-n3">
            {{ message }}
          </div>
        </template>
      </c-text-field>
    </v-col>
  </v-row>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapActions, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'AdSmartThresholdField',
    components: {
      CTextField
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['hoursThreshold', 'impressionsThreshold', 'errors'])
    },
    methods: {
      ...mapActions('adForm', ['setHoursThreshold', 'setImpressionsThreshold'])
    }
  }
</script>
