<template>
  <v-card elevation="0">
    <v-card-title class="secondary">
      <header-ad-form :ad-format="adFormat" />
    </v-card-title>

    <v-card-text class="secondary-lighten pt-4 pb-0">
      <component :is="componentName" ref="form" :preseted-size="presetedSize" class="pb-2" />
    </v-card-text>

    <v-card-actions class="secondary-lighten px-4 pt-0 pb-6">
      <c-btn
        v-if="addCreativeIsShown"
        color="primary"
        outlined
        depressed
        large
        class="py-3 px-4"
        :label="$t('main.ad_form.add')"
        :icon-props="{
          icon: '$plus',
          size: 14
        }"
        @click="addCreative()"
      />
      <v-spacer />

      <div class="d-flex flex-column align-end">
        <save-creative-button :id="seleniumIds.SELENIUM_TEST_AD_FORM_SAVE" max-width="95" @click="save()" />
        <errors
          v-if="!imagesAreUploading"
          class="d-flex justify-end py-2"
          :error-messages="$t('main.ad_form.image_upload_error')"
        />
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import HeaderAdForm from '@/components/ads/AdForm/HeaderAdForm.vue'
  import PopunderForm from '@/components/ads/PopunderForm/Index.vue'
  import BannerForm from '@/components/ads/BannerForm/Index.vue'
  import PushForm from '@/components/ads/PushForm/Index.vue'
  import InstreamForm from '@/components/ads/InstreamForm/Index.vue'
  import NativeAdForm from '@/components/ads/NativeAdForm/Index.vue'
  import DirectLinkForm from '@/components/ads/DirectLinkForm/Index.vue'
  import InterstitialsForm from '@/components/ads/InterstitialsForm/Index.vue'
  import IosCalendarForm from '@/components/ads/IosCalendarForm/Index.vue'
  import GalleryAdForm from '@/components/ads/GalleryAdForm/Index.vue'
  import InPageAdForm from '@/components/ads/InPageAdForm/Index.vue'
  import SaveCreativeButton from '@/components/ads/SaveCreativeButton.vue'

  export default {
    name: 'AdForm',
    components: {
      Errors,
      HeaderAdForm,
      PopunderForm,
      BannerForm,
      PushForm,
      InstreamForm,
      NativeAdForm,
      DirectLinkForm,
      InterstitialsForm,
      IosCalendarForm,
      GalleryAdForm,
      InPageAdForm,
      CBtn,
      SaveCreativeButton
    },
    props: {
      adFormat: {
        type: String,
        required: true
      },
      isCampaignForm: {
        type: Boolean,
        default: false
      },
      presetedSize: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        imagesAreUploading: true,
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['name', 'isDuplicate']),
      ...mapGetters('adForm', ['getAdFormRequest', 'getIsEditing', 'adTypeIsImage']),
      componentName() {
        return (
          {
            [adFormats.POPUNDER]: PopunderForm,
            [adFormats.BANNER]: BannerForm,
            [adFormats.WEB_PUSH]: PushForm,
            [adFormats.IN_STREAM_AD]: InstreamForm,
            [adFormats.NATIVE_AD]: NativeAdForm,
            [adFormats.DIRECT_LINK]: DirectLinkForm,
            [adFormats.INTERSTITIAL]: InterstitialsForm,
            [adFormats.IOS_CALENDAR]: IosCalendarForm,
            [adFormats.GALLERY]: GalleryAdForm,
            [adFormats.IN_PAGE_AD]: InPageAdForm
          }[this.adFormat] || {}
        )
      },
      addCreativeIsShown() {
        return (this.getIsEditing || this.isDuplicate) && !this.adTypeIsImage && !this.adFormatIsDirectLinkOrGallery
      },
      adFormatIsDirectLinkOrGallery() {
        return this.adFormat === adFormats.GALLERY || this.adFormat === adFormats.DIRECT_LINK
      }
    },
    watch: {
      adFormat: {
        immediate: true,
        handler(adFormat) {
          this.resetAdFormHandler()
          this.presetedSizeHandler()
          this.setAdFormatType(adFormat)
        }
      }
    },
    created() {
      this.fetchAdFormatsTokens()
    },
    methods: {
      ...mapActions('adForm', [
        'resetAdForm',
        'setSize',
        'saveForm',
        'addCreative',
        'setAdFormatType',
        'fetchAdFormatsTokens'
      ]),
      resetAdFormHandler() {
        if ((!this.getIsEditing && !this.isDuplicate) || this.isCampaignForm) {
          this.resetAdForm()
        }
      },
      presetedSizeHandler() {
        if (this.presetedSize) {
          this.setSize(this.presetedSize)
        }
      },
      checkFileUploads() {
        if (!this.$refs.form.$refs.creatives) return true

        return this.$refs.form.$refs.creatives?.checkFilesUpload()
      },
      async save() {
        this.imagesAreUploading = this.checkFileUploads()
        if (!this.imagesAreUploading) return

        const adData = await this.saveForm(this.getAdFormRequest)
        if (adData?.name) {
          this.$showSuccessNotification(this.$t('main.ad_form.store_ad', { name: adData.name }))
          this.$emit('saved', adData)
        }
      }
    }
  }
</script>
