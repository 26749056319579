<template>
  <div :class="[{ 'position-sticky-preview': !previewIsAnchor }]">
    <instream-preview-dialog
      v-if="previewIsFullscreen"
      :preview-is-fullscreen="previewIsFullscreen"
      :creative="creativeForPreview"
      @close-dialog="previewIsFullscreen = false"
    />

    <v-card v-show="!previewIsFullscreen" class="black pa-3 pa-sm-8 pt-sm-0" elevation="0">
      <v-card-title class="d-flex align-center justify-center px-0 pt-1 pb-2 py-sm-4">
        <c-btn
          :icon-props="{
            size: 20,
            color: 'primary',
            icon: '$update'
          }"
          class="mx-2"
          icon
          @click="previewIsUpdateHandler()"
        />

        <c-btn
          :icon-props="{
            size: 20,
            color: previewColorFullscreen,
            icon: '$full-screen'
          }"
          class="mx-2"
          icon
          @click="previewIsFullscreen = true"
        />

        <c-btn
          :icon-props="{
            size: 20,
            color: previewColorAnchor,
            icon: '$anchor'
          }"
          class="mx-2"
          icon
          @click="previewIsAnchor = !previewIsAnchor"
        />
      </v-card-title>

      <div class="preview rounded">
        <instream-preview :preview-is-update="previewIsUpdate" :creative="creativeForPreview" />
      </div>
    </v-card>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import InstreamPreviewDialog from '@/components/ads/InstreamForm/InstreamPreviewDialog.vue'
  import InstreamPreview from '@/components/ads/InstreamForm/InstreamPreview.vue'

  export default {
    name: 'InstreamPreviewCard',
    components: {
      InstreamPreviewDialog,
      InstreamPreview,
      CBtn
    },
    props: {
      creativeForPreview: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        previewIsUpdate: false,
        previewIsFullscreen: false,
        previewIsAnchor: true
      }
    },
    computed: {
      previewColorFullscreen() {
        return this.previewIsFullscreen ? 'primary' : 'white'
      },
      previewColorAnchor() {
        return this.previewIsAnchor ? 'primary' : 'white'
      }
    },
    methods: {
      previewIsUpdateHandler() {
        if (!this.previewIsUpdate) {
          this.previewIsUpdate = true
        } else {
          this.previewIsUpdate = false

          this.$nextTick(() => {
            this.previewIsUpdate = true
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    height: 344px;
    background: var(--v-secondary-lighten-base);
  }
  .position-sticky-preview {
    position: sticky;
    bottom: 0;
    z-index: 5;
  }
</style>
