<template>
  <div>
    <ad-name-field />
    <ad-type-field
      class="mb-4"
      :ad-types="inStreamAdTypes"
      :title="$t('main.ad_form.select_in_stream_type_ad_type')"
    />
    <select-skip-time class="mb-4" />
    <ad-priority v-if="rotationIsRandom" />
    <creatives ref="creatives" />
    <rotation-type-field v-if="!creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdTypeField from '@/components/ads/AdTypeField.vue'
  import SelectSkipTime from '@/components/ads/InstreamForm/SelectSkipTime.vue'
  import RotationTypeField from '@/components/ads/RotationTypeField.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import Creatives from '@/components/ads/Creatives.vue'

  export default {
    name: 'InstreamForm',
    components: {
      AdNameField,
      AdTypeField,
      SelectSkipTime,
      AdPriority,
      Creatives,
      RotationTypeField,
      AdSmartThresholdField
    },
    computed: {
      ...mapGetters('settings', ['inStreamAdTypes']),
      ...mapGetters('adForm', ['rotationIsRandom', 'rotationTypeIsSmart', 'creativeIsSingle'])
    }
  }
</script>
